<template>
  <div class="task-card-list__wrapper">
    <div class="task-card-list">
      <div
        v-for="(item, index) in taskList"
        :key="index"
        class="task-card-list__item"
      >
        <div class="task-card-list__item__title">{{ taskName }}</div>
        <div class="task-card-list__item__center-bar">
          <div class="center-bar__left">
            <span class="center-bar__left__label"
              >消耗{{ item.lim }}金币 (</span
            >
            <span class="center-bar__left__value">{{ item.ls }}</span>
            <span class="center-bar__left__label">/{{ item.lim }})</span>
          </div>
          <div class="center-bar__right">
            <div class="center-bar__right__go-btn" @click="onGoTo(item)">
              {{ item.ls > item.lim ? "立即领取" : "立即前往" }}
            </div>
          </div>
        </div>
        <div class="task-card-list__item__footer">
          <div class="task-card-list__item__footer__label">奖励：保底</div>
          <div class="task-card-list__item__footer__value">
            {{ ` ${item.min} ` }}
          </div>
          <div class="task-card-list__item__footer__label">金币 最高</div>
          <div class="task-card-list__item__footer__value">
            {{ ` ${item.max} ` }}
          </div>
          <div class="task-card-list__item__footer__label">金币</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "taskCardList",
  props: {
    taskName: {
      type: String,
      required: true,
    },
    taskList: {
      type: Array,
      required: true,
    },
    onGoTo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.task-card {
}

.task-card-list__wrapper {
  margin: 0 16px;
  padding-bottom: 50px;

  .task-card-list {
    background-color: #090e14;
    overflow: hidden;

    .task-card-list__item {
      margin: 12px;

      padding: 16px 12px;
      box-shadow: 0px 0px 13.276px 0px rgba(89, 155, 228, 0.3) inset;

      .task-card-list__item__title {
        font-family: "PingFang SC";
        font-size: 14px;
        font-weight: 600;

        color: #599be4;
      }

      .task-card-list__item__center-bar {
        display: flex;
        align-items: center;

        margin-top: 10px;
        margin-bottom: 4px;

        .center-bar__left {
          display: flex;
          align-items: center;
          .center-bar__left__label {
            color: #fff;
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
          }

          .center-bar__left__value {
            color: var(--yellow, #f8c544);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
          }
        }

        .center-bar__right {
          margin-left: auto;

          .center-bar__right__go-btn {
            width: 65px;
            line-height: 28px;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-image: url("~@/assets/img/common/btn-bg--yellow-130-56.png");

            color: var(--black1, #0d141a);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 12px;
          }
        }
      }

      .task-card-list__item__footer {
        display: flex;
        align-items: center;

        .task-card-list__item__footer__label {
          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-weight: 400;
        }

        .task-card-list__item__footer__value {
          margin: 0 4px;

          color: var(--yellow, #f8c544);
          font-family: "PingFang SC";
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
