<template>
  <div>
    <div class="sign-day__wrapper">
      <div class="sign-day">
        <div class="sign-day__banner__wrapper">
          <div class="sign-day__banner">
            <img
              class="img-cover"
              src="@/assets/task/4c0298b7033572834c21c8c4ba92b3e.jpg"
            />
          </div>
        </div>
        <div class="sign-day__total-num__bar">
          本月累计签到
          <span class="sign-day__total-num__bar__yellow-text">{{
            dayNum
          }}</span>
          天
        </div>
        <div class="sign-day__accumlate-list">
          <div
            class="sign-day__accumlate-list__item"
            v-for="item in accumlates.slice(0, 3)"
            :key="item.day"
            @click="contniuSignRecharge(item.day)"
          >
            <div class="sign-day__accumlate-list__item__prize-img">
              <img class="img-cover" :src="item.img" alt="" srcset="" />
            </div>
            <div class="sign-day__accumlate-list__item__label">
              累计签到{{ item.day }}天
            </div>
          </div>
        </div>
        <div class="sign-day__btn">
          <img
            class="img-cover"
            src="@/assets/img/sign/btn-sign.png"
            alt=""
            srcset=""
            @click="startSign"
          />
        </div>
      </div>
    </div>
    <div class="sign-rule">
      <div class="sign-rule__title">签到规则</div>
      <div class="content">
        <div
          class="content__item"
          v-for="(item, index) in accumlates"
          :key="item.day"
        >
          <span
            >{{ index + 1 }}.当月连续签到 {{ item.day }} 天流水大于 {{ item.ls }} 获得最高
            
            {{ item.jb }}金币随机奖励</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipsImage:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAbCAYAAABiFp9rAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAADGUlEQVRIx5XWS6hWVRQH8N/+uoRWFJQQ4U64ZYNETSqJBlkkivkgsmMRghHUVLTHRLNUuvaGaNLMgZEN2mH5SFMiLBzlwK5mQg/DdkRhT0ihQafB2Rc+P77vu8c9O2vttf/r/z977bWCSVad4o14CAswC9cU1584gX3YFaqch50ThgDMxRiWYRyHcBw/ly3XYTYWY24B3BiqPN4KqE6xgw14DnuxNVT52CSs5+FZ3I+tGAtV/m8gUJ3ipXgbC/FYqPKeyaTtiV+KHfgUq0OV/53wdbo2BWzHHbgzVHlPneIlFwMUqvxRib8d28uZFwJhLR7AilDlb4ptpE7xtTrF0YsA+w6LcB/WXSBdneIMfI0nQpV39sixGB/gZbwSqny+pYyrioyzQpVPTzDajCO9ICXDg/i47Dlep7i8JbP3cLTECXWK0/ATFoUqfzYgu1GcxJRi2oP1RaZhrO7WlEXsYCXODAIp2Z3Gq12mFYXd5jrFqUPiDuN7rOxorvL+Fmq8hDNd31PxPE7UKa4YErcfCzua6v6ihebn8FQf1w3YXae4t05xZh//UczpIPZkOgws4eAA9zKM1ylu6ZHzR+UfXYlzbYDKemuIb6rm6Tpap3h1sf2Fyzv4G5e1QSiV/uQk23Zjeajy7+X7KvwzgowZLdmswl0DfN9iXajyvh779cgdTU+5rQWbKS684hPrfJFrTh8QuBUnRvBJCzngmT7MPywsfhgStxRvdr8M94YqHxnAZjpO4YoumdaGKg+tvzrFBYXI9E6o8lm8q2lcg9ZYATmPTZg9GUhZL2BnqPKvI8WwBSfrFB8MVX6/J6v5WINdRaZWNVenuBLz8WivY32d4m/d1V2nGOoUd9QpLmlzeFfczDrFs3WKT0/YuhvfG5p36VCZfGhe68dDlQ9cBMio5vU4gNcn7P1mhndwD9a0/A/d8Us0ze5zPNI9MwyagjZpLscubGsxBd2CjZpR4EVsHjoF9Qnepun9xzQNbBy/lC3Xaua5RZhXZN8Qqvxlv/MGAnUB3oSHNZPqzZhWXH/gKxxGClU+Neyc/wF0QB/qGtoTJAAAAABJRU5ErkJggg==",
      accumlates: [
        {
          day: 7,
          img: require("@/assets/task/sign_get_1.png"),
          jb: 58,
          ls: 300,
        },
        {
          ls: 300,
          day: 15,
          jb: 188,
          img: require("@/assets/task/sigin_get_2.png"),
        },
        {
          ls: 300,
          jb: 288,
          day: 30,
          img: require("@/assets/task/sigin_get_3.png"),
        },
        {
          ls: 500,
          day: 7,
          img: require("@/assets/task/sign_get_1.png"),
          jb: 88,
        },
        {
          ls: 500,
          day: 15,
          jb: 188,
          img: require("@/assets/task/sigin_get_2.png"),
        },
        {
          ls: 500,
          jb: 388,
          day: 30,
          img: require("@/assets/task/sigin_get_3.png"),
        },
        {
          ls: 800,
          day: 7,
          img: require("@/assets/task/sign_get_1.png"),
          jb: 108,
        },
        {
          ls: 800,
          day: 15,
          jb: 388,
          img: require("@/assets/task/sigin_get_2.png"),
        },
        {
          ls: 800,
          jb: 688,
          day: 30,
          img: require("@/assets/task/sigin_get_3.png"),
        },
      ],
      dayNum: 0,
    };
  },
  created() {
    this.userSign();
  },
  methods: {
    async userSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const {
        data: { data },
      } = await this.$axios.post(
        "/index/User/sign",
        this.$qs.stringify(params)
      );
      this.dayNum = data.list[0].sign;
    },
    async startSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_in",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.$message.success("签到成功");
        this.dayNum += 1;
      } else {
        this.$message.error(data.msg);
      }
    },
    async contniuSignRecharge(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        sign__id: id,
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_welfarenew",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.$message.success("领取成功");
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sign-day__wrapper {
  margin: 0 16px;

  .sign-day {
    background-color: #090e14;
    padding: 10px;

    .sign-day__banner__wrapper {
      position: relative;
      padding-bottom: 144 / 319 * 100%;

      .sign-day__banner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }

    .sign-day__total-num__bar {
      margin-top: 20px;
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      .sign-day__total-num__bar__yellow-text {
        color: var(--yellow, #f8c544);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .sign-day__accumlate-list {
      display: flex;
      align-items: center;

      .sign-day__accumlate-list__item {
        width: 33.33%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .sign-day__accumlate-list__item__prize-img {
          width: 87px;
          height: 81px;
          flex-shrink: 0;
        }
        .sign-day__accumlate-list__item__label {
          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .sign-day__btn {
      margin: auto;
      margin-top: 20px;

      width: 200px;
      height: 60px;
    }
  }
}

.sign-rule {
  margin: 16px;

  .sign-rule__title {
    margin-bottom: 10px;
    
    color: #fff;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .content {
    padding-bottom: 20px;

    .content__item {
      margin-right: 4px 0;

      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      .content__item:nth-child(3n) {
        border-bottom: 2px solid #ffffff; 
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
