<template>
  <TaskCardList taskName="对战奖励" :taskList="list"  :onGoTo="consumeRecharge"></TaskCardList>
  <!-- <div class="bind-box-task">
    <div class="box-item" v-for="(item, index) in list" :key="index">
      <div class="left">
        <header>
          <img src="@/assets/task/box.png" alt="" srcset="" />
          <span>每日对战</span>
        </header>
        <div class="use">
          每日对战消耗{{ item.lim}}金币（<span style="color: #e9b108">{{
            item.ls
          }}</span
          >/{{ item.lim }}）
        </div>
        <div class="jiangli">
          任务奖励： 保底{{ item.min }}
          <img src="@/assets/new/money.png" alt="" srcset="" />
          最高{{ item.max }}
          <img src="@/assets/new/money.png" alt="" srcset="" />
        </div>
      </div>
      <div class="right" @click="consumeRecharge(item)">
        {{ item.ls > item.lim ? "立即领取" : "立即前往" }}
      </div>
    </div>
  </div> -->
</template>

<script>
import TaskCardList from './taskCardList/index.vue'

export default {
  components: {
    TaskCardList
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getBindBoxTaskList();
  },
  methods: {
    async getBindBoxTaskList() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const { data } = await this.$axios.post(
        "/index/Activity/battle_reward_info",
        this.$qs.stringify(params)
      );
      this.list = data.data.info;
    },
    async consumeRecharge(item) {
      if (item.ls > item.lim) {
        const params = {
          id: item.id,
          player_id: localStorage.getItem("id"),
        };
        const { data } = await this.$axios.post(
          "/index/Activity/battle_task",
          this.$qs.stringify(params)
        );
        if (data.status) {
          this.$message.success("领取成功");
        } else {
          this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/Lucky",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>

</style>
