import { render, staticRenderFns } from "./combat-task.vue?vue&type=template&id=fddb91c4&scoped=true"
import script from "./combat-task.vue?vue&type=script&lang=js"
export * from "./combat-task.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fddb91c4",
  null
  
)

export default component.exports