<template>
  <div class="responsive-container task-center">
    <myinform></myinform>
    <div style="margin-top: 60px;"></div>
    <myslide></myslide>
    <!-- my task center -->

    <div class="tab-wrapper">
      <ButtonGroup
        labelKey="name"
        valueKey="componentName"
        :btnList="tabs"
        :value="activeName"
        :onChange="(newTabValue) => activeName = newTabValue"
      >
      </ButtonGroup>
    </div>

    <keep-alive>
      <transition name="el-zoom-in-center">
        <Component :is="activeName"></Component>
      </transition>
    </keep-alive>
  </div>
</template>

<script>
import myslide from "@/components/my_slide1.vue";
import myinform from "@/components/my_inform.vue";
import ButtonGroup from "@/components/buttonGroup/index.vue";
import {
  SignDay,
  BindBoxTask,
  CombatTask,
  PrepaidRecharge,
  PasswordPacket,
  LrfTabs,
} from "./components";

export default {
  components: {
    myslide,
    myinform,
    SignDay,
    BindBoxTask,
    CombatTask,
    PrepaidRecharge,
    PasswordPacket,
    LrfTabs,
    ButtonGroup,
  },
  data() {
    return {
      // activeName: "SignDay",
      activeName: "PrepaidRecharge",
      tabs: [
        {
          componentName: "SignDay",
          name: "每日签到",
        },
        // {
        //   componentName: "BindBoxTask",
        //   name: "亏损回血",
        // },
        // {
        //   componentName: "CombatTask",
        //   name: "对战任务",
        // },
        {
          componentName: "PrepaidRecharge",
          name: "充值返利",
        },

      ],
    };
  },
};
</script>

<style lang="less" scoped src="./task-center.less">

</style>
<style lang="less" scoped >
  .tab-wrapper{
    padding: 0 16px;
  }
</style>
