<template>
  <div class="prepaid-recharge">
    <div class="banner">
      <div class="banner__content">
        <img
          class="banner__content__img img-cover "
          src="@/assets/task/recharge-tips.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
    <div class="table__out-wrapper">
      <div class="table__wrapper">
        <table class="table">
          <tr>
            <th
              class="table__th"
              v-for="item in tableConfigList"
              :key="item.prop"
            >
              {{ item.label }}
            </th>
          </tr>
          <tr
            class="table__tr"
            v-for="row in tableData"
            :key="row.money"
          >
            <td
              v-for="item in tableConfigList"
              :key="item.prop"
              class="table__tr__td"
            >
              <div class="td__recharge-icon" v-if="item.slotName === 'img'">
                <img
                  class="img-cover"
                  src="@/assets/task/recharge.png"
                  alt=""
                />
                <div v-if="row.giveCount" class="td__recharge-icon__give">送{{ row.giveCount }}</div>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const tableConfigList = [
  {
    label: "充值金额",
    prop: "money",
  },
  {
    label: "首次补给",
    prop: "img",
    slotName: "img",
  },
];

export default {
  data() {
    return {
      tableData: [
        {
          money: "100",
          giveCount: 5,
        },
        {
          money: "500",
          giveCount: 36,
        },
        {
          money: "1000",
          giveCount: 106,
        },
        {
          money: "2000",
          giveCount: 258,
        },
        {
          money: "5000",
          giveCount: 688,
        },
        {
          money: "10000",
          giveCount: 1588,
        },
      ],
      tableConfigList,
    };
  },
  methods: {
    async getChongzhils(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        id,
      };
      const { data } = await this.$axios.post(
        "/index/Activity/czls",
        this.$qs.stringify(params)
      );

      if (data.status) {
        this.$message.success("领取成功");
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.prepaid-recharge {
  background-color: rgba(9, 14, 20, 0.5);

  margin: 0 16px;

  .banner {
    margin: auto;
    position: relative;
    padding-bottom: 130 / 330 * 100%;

    .banner__content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .recharge-tips__wrapper {
    margin: auto;

    .img-cover {
    }
  }

  .table__out-wrapper {
    overflow: hidden;

    padding: 12px;

    .table__wrapper {
      overflow-x: auto;
      margin-bottom: -20px;
      padding-bottom: 20px;

      border-radius: 4px;

      .table {
        border-collapse: collapse;
        background-color: rgba(13, 20, 26, 0.5);

        min-width: 100%;

        th,
        td {
          border: none;
          padding: 0;
          margin: 0;

          white-space: nowrap;
        }

        .table__th {
          padding: 11px 16px;
          background-color: rgba(31, 51, 73, 0.7);
          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .table__tr {
          .table__tr__td {
            padding: 11px 16px;
            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            text-align: center;

            .td__recharge-icon {
              position: relative;
              margin: auto;

              width: 53px;
              height: 50px;
              flex-shrink: 0;

              .td__recharge-icon__give{
                position: absolute;
                right: 0;
                top: 0;

                line-height: 20px;
                background-color: red;

                color: #fff;
                font-size: 12px;
                padding: 0 6px;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
